<template>
  <div v-if="addEditDialog" class="dialog_cover_cloth">
    <div class="dialog_box">
      <div class="box_content">
        <div class="content_box">
          <div class="box_header">
            <div class="title">执委名单信息填写</div>
            <div @click="closeAddEditDialog" class="close_btn"></div>
          </div>
          <div class="box_conter">
            <el-form
              :model="rowData"
              :rules="rules"
              ref="ruleForm"
              label-width="6.25vw"
              class="demo-ruleForm"
            >
              <el-form-item label="类别" prop="type">
                <el-select v-model="rowData.type" placeholder="请选择类别">
                  <el-option
                    v-for="(item, index) in zwList"
                    :key="index"
                    :label="item.dataDetailed"
                    :value="item.dataDetailed"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  placeholder="请输入姓名"
                  v-model="rowData.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="出生年月" prop="birthDate">
                <el-date-picker
                  v-model="rowData.birthDate"
                  type="month"
                  placeholder="请选择出生年月"
                  format="yyyy.MM"
                  value-format="yyyy.MM"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="职务" prop="post">
                <el-input
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  placeholder="请输入职务"
                  v-model="rowData.post"
                ></el-input>
              </el-form-item>
              <el-form-item label="籍贯" prop="nativePlace">
                <el-input
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  placeholder="请输入籍贯"
                  v-model="rowData.nativePlace"
                ></el-input>
              </el-form-item>
              <el-form-item label="文化程度" prop="education">
                <el-select
                  v-model="rowData.education"
                  placeholder="请选择文化程度"
                >
                  <el-option
                    v-for="(item, index) in educationOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="领办项目" prop="project">
                <el-input
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  type="textarea"
                  :rows="3"
                  placeholder="请输入领办项目"
                  v-model="rowData.project"
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="box_footer">
            <div class="btn_box">
              <div @click="closeAddEditDialog" class="close_btn">取消</div>
              <div
                :loading="submitBtnLoading"
                @click="submitBtn"
                class="submit_btn"
              >
                {{ this.rowData.id ? "确定修改" : "确定新增" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addZwmc, editZwmc } from "@/api/zhfeZwmc";
export default {
  props: {
    addEditDialog: Boolean,
    rowData: Object,
    zwList: Array,
    type: String,
  },
  components: {},
  data() {
    return {
      submitBtnLoading: false,
      educationOptions: [
        { label: "暂无", value: "暂无" },
        { label: "小学", value: "小学" },
        { label: "初中", value: "初中" },
        { label: "高中", value: "高中" },
        { label: "大专", value: "大专" },
        { label: "本科", value: "本科" },
        { label: "研究生", value: "研究生" },
        { label: "硕士", value: "硕士" },
        { label: "博士", value: "博士" },
      ],
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        birthDate: [
          { required: true, message: "请出生年月", trigger: "change" },
        ],
        post: [{ required: true, message: "请输入职务", trigger: "blur" }],
        nativePlace: [
          { required: true, message: "请输入籍贯", trigger: "blur" },
        ],
        education: [
          { required: true, message: "请选择文化程度", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类别", trigger: "change" }],
        // project: [
        //   { required: true, message: "请输入领办项目", trigger: "blur" },
        // ],
      },
    };
  },
  watch: {},
  created() {
    if (this.type) {
      this.rowData.type = this.type;
    }
  },
  mounted() {},
  methods: {
    closeAddEditDialog() {
      this.$refs.ruleForm.resetFields();
      this.$emit("closeAddEditDialog", false);
    },
    submitBtn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let requestName = this.rowData.id ? editZwmc : addZwmc;
          this.submitBtnLoading = true;
          requestName(this.rowData)
            .then((res) => {
              console.log(res);
              this.submitBtnLoading = false;
              if (res.code == 200) {
                this.$message.success(
                  this.rowData.id ? "修改成功" : "添加成功"
                );
                this.closeAddEditDialog();
                this.$emit("getList");
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              this.submitBtnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_cover_cloth {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  .dialog_box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .box_content {
      background: transparent;
      width: 48vw;
      .bg_img {
        width: 48vw;
        img {
          width: 100%;
        }
      }
      .content_box {
        color: #fff;
        width: 48vw;
        line-height: 2vw;
        background: url("../../../assets/image/dialogBg.png") no-repeat;
        background-size: 100% 100%;
        min-height: 55vh;
        .box_header {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          margin-bottom: 1vw;
          .title {
            color: #fff;
            font-weight: bold;
            font-size: 0.9vw;
            background: #cd80a2;
            border-bottom-left-radius: 0.5vw;
            border-bottom-right-radius: 0.5vw;
            border: 0.1vw solid;
            padding: 0 2vw;
          }
          .close_btn {
            background: url("../../../assets/image/zhfe/dialogClose.png")
              no-repeat;
            background-size: 100% 100%;
            position: absolute;
            width: 2vw;
            height: 2vw;
            top: -1vw;
            right: -1vw;
            cursor: pointer;
            // opacity: 0;
            padding: 0.5vw;
          }
        }
        .box_conter {
          padding: 0 2vw;
          ::v-deep {
            .el-form-item {
              margin-bottom: 1.15vw;
            }
            .el-form-item__label {
              color: rgba(255, 212, 227, 1);
              font-size: 0.73vw;
              line-height: 2.1vw;
              padding: 0 0.62vw 0 0;
            }
            .el-textarea__inner,
            .el-input__inner {
              color: rgba(255, 212, 227, 1);
              background-color: transparent;
              &::placeholder {
                color: rgba(255, 212, 227, 1);
              }
            }
            .el-select .el-input .el-select__caret {
              color: rgba(255, 212, 227, 1);
            }
            .el-input__prefix,
            .el-input__suffix {
              color: rgba(255, 212, 227, 1);
            }
            .el-textarea {
              font-size: 0.75vw;
            }
            .el-form-item__content {
              line-height: 2vw;
              font-size: 0.75vw;
            }
            .el-input {
              font-size: 0.73vw;
            }
            .el-input--suffix .el-input__inner {
              padding-right: 1.6vw;
            }
            .el-input__inner {
              height: 2.2vw;
              line-height: 2.2vw;
              padding: 0 0 0 1.5vw;
            }
            .el-select .el-input .el-select__caret {
              font-size: 0.73vw;
            }
            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
          }
        }
        .box_footer {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1vw;
          color: #ffd4e4;
          .btn_box {
            display: flex;
            div {
              border: 0.1vw solid #ffd4e4;
              margin-right: 1vw;
              padding: 0.1vw 4vw;
              cursor: pointer;
              font-size: 0.8vw;
            }
          }
        }
      }
    }
  }
}
</style>