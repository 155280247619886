<template>
  <div v-if="importDialog" class="dialog_body">
    <div
      v-loading="loading"
      element-loading-text="导入中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="dialog_box"
    >
      <div class="dialog_content">
        <div class="content_header">
          <div class="title">执委名单信息导入</div>
          <div @click="importClose" class="close_x"></div>
        </div>
        <div class="content_conter">
          <div class="upload_box">
            <el-upload
              class="upload-demo"
              drag
              action="#"
              accept=".xls,.xlsx"
              :http-request="updataFile"
              :auto-upload="false"
              :file-list="fileList"
              :limit="1"
              :before-upload="handleBeforeUpload"
              :on-exceed="handleExceed"
              :on-change="changeFile"
              :on-remove="removeFile"
            >
              <img src="@/assets/image/wjj.png" alt="" />
              <div class="el-upload__text">
                点击或将文件拖拽到这里上传
                <div class="el-upload__tip" slot="tip">
                  支持拓展名：.xls , .xlsx
                </div>
              </div>
            </el-upload>
            <div @click="downloadTemplate" class="download_btn">下载模板</div>
          </div>
        </div>
        <div class="content_footer">
          <div class="btn_box">
            <div @click="importClose" class="close_btn">取消</div>
            <div @click="importSubmit" class="submit_btn">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { download } from "@/api/microWish";
import { importZwmcXlsx, getZwmcTemplate } from "@/api/zhfeZwmc";
import { download as downloadFile, getExt } from "@/utils/index.js";
import { listData } from "@/api/dtdata";
export default {
  props: {
    importDialog: Boolean,
  },
  components: {},
  data() {
    return {
      uploadFileUrl: "", // process.env.VUE_APP_BASE_API +上传的图片服务器地址
      headers: {
        // Authorization: "Bearer " + getToken(),
      },
      fileList: [],
      fileType: ["xls", "xlsx"],
      loading: false,
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async downloadTemplate() {
      let { rows } = await listData({
        dataModule: "zwmc",
        dataTyp: "zwmc-template",
      });
      let msg = rows[0].dataDetailed;
      let res = await getZwmcTemplate(msg);
      let filename = "执委名册导入模板.xlsx";
      let link = URL.createObjectURL(res);
      downloadFile(link, filename);
    },
    importClose() {
      this.fileList = [];
      this.$emit("importClose", false);
    },
    updataFile(file) {},
    importSubmit() {
      if (this.fileList.length > 0) {
        if (this.handleBeforeUpload(this.fileList[0].raw)) {
          let formData = new FormData();
          let status = false;
          this.loading = true;
          if (!status) {
            status = true;
            formData.append("file", this.fileList[0].raw);
            importZwmcXlsx(formData)
              .then((res) => {
                console.log(res);
                status = false;
                this.loading = false;
                if (res.msg.includes("成功")) {
                  this.$message.success("导入成功!");
                } else {
                  this.$message.info("导入失败,正在下载导入失败数据...");
                  this.importFail(res.msg);
                }
                this.fileList = [];
                this.$emit("importSuccess");
              })
              .catch((err) => {
                status = false;
                this.loading = false;
                this.$message.info(res.msg);
              });
            // this.importClose();
          }
        }
      } else {
        this.$message.info("请选择上传文件!");
      }
    },
    async importFail(val) {
      let filename = "执委名册导入失败数据.xlsx";
      let file = await download({ fileName: val, delete: true });
      let link = URL.createObjectURL(file);
      downloadFile(link, filename);
    },
    changeFile(file, fileList) {
      this.fileList = fileList;
    },
    removeFile(file) {
      return;
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`只允许上传单个文件`);
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_body {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog_box {
    width: 44vw;
    height: 25vw;
    background: #fff;
    background: url("../../../assets/image/dialogBg.png") no-repeat;
    background-size: 44vw 25vw;
    .dialog_content {
      width: 100%;
      height: 100%;
      // padding: 1vw;
      position: relative;
      .content_header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .title {
          color: #fff;
          font-weight: bold;
          font-size: 0.9vw;
          background: #cd80a2;
          border-bottom-left-radius: 0.5vw;
          border-bottom-right-radius: 0.5vw;
          border: 0.1vw solid;
          padding: 0.5vw 2vw;
        }
        .close_x {
          background: url("../../../assets/image/zhfe/dialogClose.png")
            no-repeat;
          background-size: 100% 100%;
          position: absolute;
          width: 2vw;
          height: 2vw;
          top: -1vw;
          right: -1vw;
          cursor: pointer;
          // opacity: 0;
          padding: 0.5vw;
        }
      }
      .content_conter {
        text-align: center;
        margin-top: 3.5vw;
        .upload_box {
          position: relative;
          .download_btn {
            cursor: pointer;
            position: absolute;
            top: 10vw;
            left: 6.5vw;
            font-size: 0.8vw;
            color: rgba(255, 212, 227, 1);
          }
        }
        ::v-deep {
          .upload-demo {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            .el-upload {
              display: block;
              text-align: center;
              outline: 0;
              cursor: default;
              margin: 0 auto;
              width: 100%;
              height: 10vw;
              .el-upload-dragger {
                border-radius: 0.2vw;
                box-sizing: border-box;
                width: 70%;
                height: 9vw;
                cursor: pointer;
                overflow: hidden;
                background: rgba(1, 20, 40, 0.45);
                border: 1px solid rgba(255, 212, 227, 1);
                margin: 0vw auto;
                img {
                  width: 1.5vw;
                  height: 1.5vw;
                  margin: 2vw 0 0.5vw;
                }
                .el-upload__text {
                  color: rgba(255, 212, 227, 1);
                  font-size: 0.8vw;
                  .el-upload__tip {
                    margin-top: 0.5vw;
                    color: rgba(255, 212, 227, 1);
                    font-size: 0.8vw;
                  }
                }
              }
            }
            .el-upload-list {
              margin: 0vw;
              padding: 0;
              list-style: none;
              width: 70%;
              height: 3vw;
              overflow-y: auto;
              text-align: left;
              position: absolute;
              bottom: -4vw;
              .el-upload-list__item {
                .el-upload-list__item-name {
                  color: rgba(255, 212, 227, 1);
                }
              }
            }
          }
        }
      }
      .content_footer {
        position: absolute;
        bottom: 1vw;
        left: 0;
        width: 98%;
        display: flex;
        justify-content: center;
        padding: 0 1vw;
        .btn_box {
          display: flex;
          align-items: center;
          color: rgba(255, 212, 227, 1);
          font-size: 0.8vw;
          div {
            border: 1px solid rgba(255, 212, 227, 1);
            padding: 0.8vw 4vw;
            cursor: pointer;
          }
          .close_btn {
            margin-right: 2vw;
          }
        }
      }
    }
  }
}
</style>