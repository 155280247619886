import request from '@/utils/request'

// 获取执委名称
export function getZwList(query) {
  return request({
    url: `/zhfe/zwmc/list`,
    method: 'get',
    params: query
  })
}

// 新增执委名称
export function addZwmc(query) {
  return request({
    url: `/zhfe/zwmc`,
    method: 'post',
    data: query
  })
}

// 修改执委名称
export function editZwmc(query) {
  return request({
    url: `/zhfe/zwmc`,
    method: 'put',
    data: query
  })
}

// 删除执委名称
export function deleteZwmc(id) {
  return request({
    url: `/zhfe/zwmc/${id}`,
    method: 'delete',
  })
}

// 下载执委名册导入模板
export function downloadZwmcFile() {
  return request({
    url: `/zhfe/zwmc/export`,
    method: 'get'
  })
}

// 导入爱心包裹xlsx
export function importZwmcXlsx(query) {
  return request({
    url: `/zhfe/zwmc/importZWMC`,
    method: 'post',
    data: query
  })
}

export function getZwmcTemplate(url) {
  return request({
    url: `/common/download/resource?name=${url}`,
    method: 'get',
    responseType: 'blob'
  })
}