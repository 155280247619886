
/***
 * 获取文件后缀名
 * 使用场景：上传文件判断后缀名
 * 获取文件后缀名
 * @param {String} filename
 */

export function getExt(filename) {
    if (typeof filename == 'string') {
        return filename.split('.').pop().toLowerCase()
    } else {
        throw new Error('filename must be a string type')
    }
}

export function download(link, name) {
    if (!name) {
        name = link.slice(link.lastIndexOf('/') + 1)
    }

    let eleLink = document.createElement('a')
    eleLink.download = name
    eleLink.style.display = 'none'
    eleLink.href = link
    document.body.appendChild(eleLink)
    eleLink.click()
    document.body.removeChild(eleLink)
}

export function resetPage(pageNum, pageSize, total, num) {
    const page = Math.ceil((total - num) / pageSize)
    const currentPage = page > pageNum ? pageNum : page
    return currentPage === 0 ? 1 : currentPage
}