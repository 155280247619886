import request from '@/utils/request'
// 获取党支部列表
export function getPartyBranchList(query) {
  return request({
    url: `/szdt/wjdt/party/list`,
    method: 'get',
  })
}

// 获取爱心包裹列表
export function getMicroWishList(query) {
  return request({
    url: `/szdt/wjdt/wish/list`,
    method: 'get',
    params: query
  })
}

// 修改爱心包裹
export function editMicroWish(query) {
  return request({
    url: `/szdt/wjdt/wish`,
    method: 'put',
    data: query,
    headers: { 'Content-Type': 'application/json' }
  })
}

// 新增爱心包裹信息
export function addMicroWish(query) {
  return request({
    url: `/szdt/wjdt/wish`,
    method: 'post',
    data: query,
  })
}

// 删除爱心包裹
export function delMicroWish(id) {
  return request({
    url: `/szdt/wjdt/wish/${id}`,
    method: 'delete'
  })
}

export function download(params) {
  return request({
    url: "/common/download",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      isToken: true
    },
    responseType: "blob",
    params: params
  });
}

// 下载爱心包裹导入模板
export function downloadMicroWishFile() {
  return request({
    url: `/szdt/wjdt/wish/importTemplate`,
    method: 'get'
  })
}

// 导入爱心包裹xlsx
export function importXlsx(query) {
  return request({
    url: `/szdt/wjdt/wish/importData`,
    method: 'post',
    data: query
  })
}