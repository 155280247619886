<!-- 提示 -->
<template>
  <div class="dialogAlert">
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30vw"
      center
    >
      <div class="dialogTitleBox">
        <div class="dialogTitle">
          {{ dialogTitle ? dialogTitle : "系统提示" }}
        </div>
        <div class="dialogClose" @click="closeDialog"></div>
      </div>
      <div class="dialogMain">
        <!-- 删除文字提示 -->
        <slot name="txt"></slot>
        <!-- 编辑的内容 -->
        <slot></slot>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="dialogSublimt">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogVisible", "dialogTitle", "dialogData"],
  data() {
    return {};
  },
  methods: {
    // 取消
    closeDialog() {
      this.$emit("closeDialog");
    },
    // 确定
    dialogSublimt() {
      this.$emit("determine", this.dialogData);
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
::v-deep.dialogAlert {
  .el-dialog {
    background: url("../../../assets/image/dialogBg.png") no-repeat;
    background-size: 100% 100%;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .dialogTitleBox {
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-content: center;
      .dialogTitle {
        text-align: center;
        // background: url("../../../assets/image/homeclosetitle.png") no-repeat;
        // background-size: 100% 100%;
        background: #cd80a2;
        border: 0.1vw solid #fff;
        border-bottom-left-radius: 0.5vw;
        border-bottom-right-radius: 0.5vw;
        padding: 0 2vw;
        height: 4vh;
        line-height: 4vh;
        color: #fff;
        font-weight: 700;
        font-size: 0.9vw;
      }
      .dialogClose {
        width: 3vh;
        height: 3vh;
        position: absolute;
        top: -1.2vh;
        right: -0.7vw;
        cursor: pointer;
        background: url("../../../assets/image/dialogClose.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .dialogMain {
      min-height: 20vh;
      // line-height: 20vh;
      text-align: center;
      color: #ffd4e3;
      font-size: 0.9vw;
      padding: 1.5vw;
      margin-top: 2vw;
    }
  }
  .dialogTxt {
    height: 30vh;
    line-height: 30vh;
  }
  .dialog-footer {
    button {
      padding: 0;
      width: 9.3vw;
      height: 4.6vh;
      line-height: 4.6vh;
      background: #002953;
      border: 1px solid #ffd4e4;
      border-radius: 0.2vw;
      color: #ffd4e4;
      cursor: pointer;
    }
  }
  .homeimgbox {
    padding: 5vh 0;
    .hi-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: initial;
      margin-bottom: 2vh;
      .uploaderTips {
        font-size: 0.7vw;
        margin-top: 1vh;
        text-align: left;
      }
      .hi-txt {
        width: 9vw;
        text-align: right;
        margin-right: 2vw;
        font-size: 0.8vw;
      }
      .avatar-uploader {
        width: 6.5vw;
        height: 7vw;
        line-height: 7vw;
        background: #011428;
        border: 1px solid #1863bf;
        .el-upload {
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .hi-right {
        flex: 1;
        text-align: left;
        .hi-tips {
          font-size: 0.6vw;
          margin-left: 0.6vw;
        }
        .hi-input {
          width: 10vw;
          border-radius: 0.2vw;
          input {
            background-color: #011428;
            border: 1px solid #1863bf;
            font-size: 0.6vw;
            color: #fff;
          }
        }
      }
    }
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #011428;
    border-color: #1863bf;
  }
}
</style>